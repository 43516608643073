import React, { useState, useRef, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { compose } from "recompose"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

// export const IndexQuery = graphql`
//   query {
//     allStoreLocationsCsv {
//       edges {
//         node {
//           wpsl_id
//           name
//           country_iso
//           address
//           city
//           zip
//           lat
//           lng
//           phone
//           url
//           # status
//           # permalink
//           #description
//           # excerpt
//           #author
//           #date
//           #address2
//           #state
//           #country
//           #hours
//           #fax
//           #email
//           #image
//           #category
//         }
//       }
//     }
//   }
// `

const Map = ({ data }) => {
  useEffect(() => {
    // mcScript1.onload = mapLoad
    // document.body.appendChild(mcScript1)
    // const mapScript = document.createElement("script")
    //  nstall/"
    // mapScript.id = "store-map"
    // mapScript.onload = mapLoad
    // document.body.appendChild(mapScript)
  }, [])

  return (
    <Layout>
      <SEO title="Find Us" />
      <MapWrapper>
        <iframe
          id="destini"
          src="//destinilocators.com/belgianboys/site/locator.php?RFR=https://belgianboys.com&amp;&MM=panel2"
          // scrolling="no"
          frameBorder="0"
          style={{ width: "100%", height: "923px" }}
          allow="geolocation"
          title="Locator application powered by Destini"
        >
          Belgian Boys Product Locator. Your browser does not support iframes.
        </iframe>
      </MapWrapper>
    </Layout>
  )
}

export default Map

const MapWrapper = styled.div``

// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from "react-google-maps"
// import { MarkerInfo, SearchContainer, Wrapper } from "../styles/MapStyles"

// import { phone, web, mapMarker } from "../utils/imageUpload"

// import styled from "styled-components"

// const apiKey = process.env.GATSBY_GOOGLE_MAP_API

// const MapWithAMarker = compose(
//   withScriptjs,
//   withGoogleMap
// )(props => {
//   return (
//     <GoogleMap defaultZoom={5} defaultCenter={{ lat: 41.5, lng: -100.0 }}>
//       {props.markers.map((marker, index) => {
//         return (
//           <Marker
//             //icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
//             icon={{
//               url: mapMarker,
//               size: { width: 25, height: 60 },
//               anchor: { x: 15, y: 50 },
//               scaledSize: { width: 25, height: 35 },
//             }}
//             onClick={() => props.onClick(marker.node)}
//             key={marker.node.wpsl_id}
//             position={{
//               lat: Number(marker.node.lat),
//               lng: Number(marker.node.lng),
//             }}
//           >
//             {props.selectedMarker === marker.node && (
//               <InfoWindow>
//                 <MarkerInfo>
//                   <div>
//                     {marker.node.img != 0 && (
//                       <div
//                         style={{
//                           margin: "0",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         }}
//                       >
//                         <img width="150px" src={marker.node.img} alt="" />
//                       </div>
//                     )}
//                     <p style={{ fontWeight: "bold" }}>{marker.node.name}</p>
//                     <p> {marker.node.address}</p>
//                     <p>
//                       {marker.node.zip} {marker.node.city}
//                     </p>
//                   </div>
//                   {marker.node.phone && (
//                     <p>
//                       <img
//                         className="icon"
//                         width="15px"
//                         src={phone}
//                         alt={marker.node.name}
//                       />
//                       {marker.node.phone}
//                     </p>
//                   )}
//                   {marker.node.url && (
//                     <a
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       href={marker.node.url}
//                     >
//                       <p>
//                         <img
//                           className="icon"
//                           width="15px"
//                           src={web}
//                           alt={marker.node.name}
//                         />
//                         View Website
//                       </p>
//                     </a>
//                   )}
//                 </MarkerInfo>
//               </InfoWindow>
//             )}
//           </Marker>
//         )
//       })}
//     </GoogleMap>
//   )
// })

// const FindUs = ({ data }) => {
//   const stores = data.allStoreLocationsCsv.edges

//   const [locations] = useState(stores)
//   const [list, setlist] = useState(stores)
//   const [selectedMarker, setselectedMarker] = useState(false)
//   const [search, setsearch] = useState("")

//   const handleClick = (marker, event) => {
//     setselectedMarker(marker)
//   }

//   const handleChange = e => {
//     e.preventDefault()
//     setsearch(e.target.value)

//     const filtered = locations.filter(location => {
//       return (
//         location.node.name
//           .toLowerCase()
//           .includes(e.target.value.toLowerCase()) ||
//         location.node.city
//           .toLowerCase()
//           .includes(e.target.value.toLowerCase()) ||
//         location.node.zip.toLowerCase().includes(e.target.value.toLowerCase())
//       )
//     })
//     setlist(filtered)
//   }
//   if (!(typeof window !== "undefined")) {
//     return (
//       <Layout>
//         <h1>Loading</h1>
//       </Layout>
//     )
//   }
//   return (
//     <Layout>
//       <SEO title="Find Us" />
//       <Wrapper id="BBMap">
//         <SearchContainer>
//           <input
//             onChange={handleChange}
//             type="text"
//             placeholder="Enter a store name, city or zipcode"
//           />
//           <ul>
//             {list.map((location, index) => {
//               return (
//                 <li
//                   key={location.node.wpsl_id}
//                   onClick={() => handleClick(location.node)}
//                 >
//                   <h5>{location.node.name}</h5>
//                   <h6>
//                     {location.node.address}, {location.node.city},{" "}
//                     {location.node.zip},{location.node.country_iso}
//                   </h6>
//                 </li>
//               )
//             })}
//           </ul>
//         </SearchContainer>
//         <MapWithAMarker
//           className="map"
//           selectedMarker={selectedMarker}
//           markers={locations}
//           googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
//           onClick={handleClick}
//           loadingElement={<div style={{ height: `100%` }} />}
//           containerElement={<div className="map" />}
//           mapElement={<div style={{ height: `100%` }} />}
//         />
//       </Wrapper>
//     </Layout>
//   )
// }

// export default FindUs
